<template>
	<div data-component="deal">
		<deal-summaries
			:deals="getDeals"
		/>
	</div>
</template>

<script>

	import DealSummaries from '@/components/diagrams/DealSummaries';

	export default {
		components: {
			DealSummaries
		},
		props: {
			config: {
				type: Object,
				default: () => ({
					deals:     undefined,
					fileName:  undefined
				})
			},
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getDeals () {
				return this?.config?.deals;
			},
			getGameStates () {
				if (!this.getDeals) {
					return false;
				}
				return this.getDeals.map((deal) => {
					return deal.deal;
				});
			},
			getNumberOfDeals () {
				return this?.getDeals?.length;
			},
			getCurrentDeal () {
				if (!this.getDeals &&
						!this.getDeals.length) {
					return false;
				}
				return this.getDeals[this.currentDealIndex];
			},
			getCurrentGameState () {
				if (!this.getCurrentDeal?.deal) {
					return false;
				}
				return this.getCurrentDeal.deal;
			}
		},
		methods: {
			showNextDeal () {
				if (this.getNumberOfDeals === this.currentDealIndex + 1) {
					this.currentDealIndex = 0;
					return;
				}
				return this.currentDealIndex++;
			},
			showPreviousDeal () {
				if (this.currentDealIndex === 0) {
					this.currentDealIndex = this.getNumberOfDeals - 1;
					return false;
				}
				this.currentDealIndex--;
			},
			preview () {
				this.simulatorIsVisible = true;
			},
			closeSimulator () {
				this.simulatorIsVisible = false;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal'] {}

</style>
