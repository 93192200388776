<template>
	<div data-component="bidding-interface-editor">
		<validation-observer
			data-element="fields"
			tag="div"
			ref="innerdiagram"
		>
			<input-field
				name="Current bid"
				type="text"
				:rules="getBidValidation"
				:id="`currentBid-${uuid}`"
				v-model="getCurrentBid"
			/>
			<input-field
				name="Correct answer"
				type="text"
				autocomplete="off"
				:rules="getBidValidation"
				:id="`correctAnswer-${uuid}`"
				v-model="getAnswerValue"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import InputField from '@/components/forms/InputField';

	export default {
		components: {
			InputField
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getIsCorrect: {
				get () {
					return this.$store.getters['quiz/getIsCorrectByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setIsCorrectByUuid', {
						uuid: this.uuid,
						isCorrect: value
					});
				}
			},
			getCurrentBid: {
				get () {
					return this.$store.getters['quiz/getCurrentBidByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setCurrentBidByUuid', {
						uuid: this.uuid,
						currentBid: this.sanitiseBids(value)
					});
				}
			},
			getAnswerValue: {
				get () {
					return this.$store.getters['quiz/getAnswerValueByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setAnswerValueByUuid', {
						uuid: this.uuid,
						answerValue: this.sanitiseBids(value)
					});
				}
			},
			getBidValidation () {
				return {
					required: true,
					bids: {
						type: 'single',
						dealer: 'N'
					}
				};
			}
		},
		watch: {},
		mounted () {},
		methods: {
			sanitiseBids (bids) {
				return bids.toUpperCase().replace(' ', ',');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bidding-interface-editor'] {
	}

</style>
