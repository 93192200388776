<template>
	<div data-component="deal-simulator">
		<simulator
			:mode="getSimulatorMode"
			:gameIds="getGameIds"
			:gameStates="getGameStates"
			:playersPositions="getPlayersPositions"
			:bidExplanationsList="getBidExplanationsList"
			:bidHintsList="getBidHintsList"
			:openingLeadsList="getOpeningLeadsList"
			:claimParametersList="getClaimParametersList"
			:startWithEmptyAuction="config.startWithEmptyAuction"
			:showCloseButton="false"
		/>
	</div>
</template>

<script>

	import { SIMULATOR_MODES } from '@/consts';

	export default {
		components: {
			Simulator: () => import('@/components/simulator/Simulator')
		},
		props: {
			config: {
				type: Object,
				default: () => ({
					gameState: undefined,
					fileName: undefined
				})
			},
			nextActivity: {
				type: [Number, Boolean],
				default: undefined
			},
			type: {
				type: String,
				default: undefined
			},
			isCourseComplete: {
				type: Boolean,
				default: false
			},
			isPreview: {
				type: Boolean,
				default: false
			},
			activityProgress: {
				type: Boolean,
				default: null
			},
			mode: {
				type: [String, undefined],
				validator (value) {
					return Object.values(SIMULATOR_MODES).includes(value);
				},
				// TODO: Consider using different game mode for deals in activities
				default: SIMULATOR_MODES.PREVIEW
			}
		},
		computed: {
			getSimulatorMode () {
				return this.mode;
			},
			getConfigDeals () {
				return this.config.deals || [];
			},
			getGameIds () {
				return this.getConfigDeals.map(deal => deal.id);
			},
			getGameStates () {
				return this.getConfigDeals.map((deal) => {
					return deal.deal;
				});
			},
			getPlayersPositions () {
				return this.getConfigDeals.map(deal => deal.playerPosition);
			},
			getBidExplanationsList () {
				return this.getConfigDeals.map(deal => deal.bidExplanations);
			},
			getBidHintsList () {
				return this.getConfigDeals.map(deal => deal.bidHints);
			},
			getOpeningLeadsList () {
				return this.getConfigDeals.map(deal => deal.openingLead);
			},
			getClaimParametersList () {
				return this.getConfigDeals.map(deal => deal.claimParameters);
			}
		},
		methods: {
			closeSimulator () {
				// TODO: Add next and previous activity buttons
			}
		}
	};

</script>

<style lang="scss" scoped>
</style>
