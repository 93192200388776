<template>
	<div data-component="quiz">
		<div data-element="quiz-content">
			<diagrams
				v-if="getShowQuestionDiagrams"
				heading="Question diagrams"
				context="question"
			/>
			<div
				v-if="getQuestion"
				data-element="question"
			>
				<div
					data-element="question-text"
					v-if="getQuestion"
					v-html="getQuestion"
				/>
				<p
					v-if="getShowChooseAllMessage"
					data-element="choose-all"
				>
					Choose all correct answers
				</p>
			</div>
			<diagrams
				context="answer"
			/>
			<div
				data-element="hint-text"
				v-if="getHintString"
				v-html="getHintString"
			/>
			<div
				data-element="explanation-text"
				v-if="getExplanation"
				v-html="getExplanation"
			/>
		</div>
		<activity-buttons
			:isQuiz="true"
			:isPreview="isPreview"
		/>
	</div>
</template>

<script>

	import Diagrams         from '@/components/activities/view/activityTypes/quiz/Diagrams';
	import ActivityButtons  from '@/components/activities/view/ActivityButtons';

	export default {
		components: {
			Diagrams,
			ActivityButtons
		},
		props: {
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getShowQuestionDiagrams () {
				return this.$store.getters['quiz/getDiagramsByContext']({
					context: 'question'
				}).length > 0;
			},
			getCorrectAnswersAreHighlighted () {
				return this.$store.getters['quiz/getCorrectAnswersAreHighlighted'];
			},
			getQuizIsComplete () {
				return this.$store.getters['quiz/getQuizIsComplete'];
			},
			getQuizIsCompleteAndCorrect () {
				return this.$store.getters['quiz/getQuizIsCompleteAndCorrect'];
			},
			getQuizIsCompleteButIncorrect () {
				return this.$store.getters['quiz/getQuizIsCompleteButIncorrect'];
			},
			getMustChooseAllCorrectAnswers () {
				return this.$store.getters['quiz/getMustChooseAllCorrectAnswers'];
			},
			getCurrentProgress () {
				return this.$store.getters['quiz/getCurrentProgress'];
			},
			getQuestion () {
				return this.$store.getters['quiz/getQuestion'];
			},
			getHint () {
				return this.$store.getters['quiz/getHint'];
			},
			getShowChooseAllMessage () {
				return this.getMustChooseAllCorrectAnswers;
			},
			// strings and messages
			getHintString () {
				if (!this.getHint?.length) {
					return false;
				}
				if (this.getCurrentProgress === null ||
						this.getCurrentProgress === true) {
					return false;
				}
				return this.getHint;
			},
			getExplanation () {
				if (this.getCorrectAnswersAreHighlighted) {
					return this.$store.getters['quiz/getExplanation'];
				}
				if (this.getCurrentProgress === false ||
					this.getCurrentProgress === null) {
					return false;
				}
				return this.$store.getters['quiz/getExplanation'];
			}
		},
		watch: {
			getQuizIsComplete () {
				if (this.getCurrentProgress === null) {
					return false;
				}
				this.setActivityProgress({
					progress: this.getCurrentProgress
				});
			}
		},
		methods: {
			setActivityProgress ({ progress }) {
				this.$store.dispatch('lesson/setActivityProgress', {
					progress
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='quiz'] {
		[data-element='quiz-content'] {
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:flex-start;
			[data-component='diagrams'] {
				width:100%;
				margin:0 0 rem(18);
			}
			[data-element='question'] {
				width:100%;
				margin:0 0 rem(32);
				text-align:center;
				background-color:$c-brand-grey-lighter-7;
				border-radius:4px;
				padding:rem(22) rem(14) rem(12);
				::v-deep [data-element='question-text'] {
					margin:0 0 rem(12);
					font-size:rem(26);
					font-family:'Montserrat', sans-serif;
					font-weight:700;
					color:$c-brand-blue;
					@include rich-text-content;
					@include rich-text-suits;
					text-align:center;
				}
				[data-element='choose-all'] {
					margin:0 0 rem(12);
					font-size:rem(16);
					text-align:center;
				}
			}
			::v-deep [data-element='hint-text'] {
				margin:0 0 rem(12);
				font-size:rem(22);
				@include rich-text-content;
				@include rich-text-suits;
				text-align:center;
			}
			::v-deep [data-element='explanation-text'] {
				margin:0 0 rem(6);
				font-size:rem(22);
				@include rich-text-content;
				@include rich-text-suits;
				text-align:center;
			}
		}
		[data-component='quiz-controls'] {
		}
	}

</style>
