<template>
	<div data-component="opening-lead-editor">
		<input-field
			type="text"
			v-model="getOpeningLead"
			name="Opening lead"
			autocomplete="off"
			:rules="getOpeningLeadValidation"
			size="xs"
		/>
	</div>
</template>

<script>

	import InputField from '@/components/forms/InputField';

	export default {
		components: {
			InputField
		},
		props: {
			value: {
				type: String,
				default: undefined
			},
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({}),
		computed: {
			getOpeningLead: {
				get () {
					return this.value;
				},
				set (newVal) {
					if (newVal.length > 2) {
						return false;
					}
					const cleanedVal = newVal.toUpperCase().replace('10', 'T');
					this.$emit('input', cleanedVal);
				}
			},
			getDealer () {
				return this?.deal?.dealer;
			},
			getAuction () {
				return this?.deal?.deal?.auction;
			},
			getDeal () {
				return this?.deal?.deal?.deal;
			},
			getOpeningLeadValidation () {
				return {
					openingLead: {
						dealer: this?.deal?.dealer,
						auction: this.getAuction,
						deal: this.getDeal
					}
				};
			}
		},
		watch: {},
		mounted () {},
		methods: {}
	};

</script>

<style lang="scss" scoped>
	[data-component='opening-lead-editor'] {
		width:200px;
	}
</style>
