<template>
	<div
		data-component="deal-matrix"
		:data-selected="getIsSelected"
		:data-highlighted="getIsHighlighted"
		:data-answer="isAnswerDiagram"
		:data-selectable="getIsSelectable"
		:data-selected-and-correct-and-quiz-is-complete="getIsSelectedAndCorrectAndQuizIsComplete"
		:data-selected-and-incorrect-and-quiz-is-complete="getIsSelectedAndIncorrectAndQuizIsComplete"
		@click="selectAnswer"
	>
		<div data-element="positions">
			<div
				data-element="position"
				v-for="(position, positionIndex) in getPositions"
				:data-position="getPositionByPosition(position)"
				:data-table-position="getTablePositionByPosition(position)"
				:key="positionIndex"
			>
				<hand
					:uuid="uuid"
					:position="position"
					:isEditMode="isEditMode"
					:isValid="isValid"
					:hand="getHands[position]"
					:errors="errors"
					:isThumbnail="isThumbnail"
					:isPartOfDealMatrix="true"
					:isSimulatorRemote="isSimulatorRemote"
					:isPartOfQuiz="isPartOfQuiz"
				/>
			</div>
			<compass
				:isSimulatorRemote="isSimulatorRemote"
			/>
		</div>
		<checkbox-field
			v-if="isEditMode"
			name="Hide empty suits"
			:id="`check-${uuid}-hide`"
			v-model="getHideEmptySuits"
		/>
		<checkbox-field
			v-if="isEditMode && isAnswerDiagram"
			name="Correct answer"
			:id="`check-${uuid}`"
			v-model="getIsCorrect"
		/>
		<answer-icon
			v-if="getAnswerIcon"
			:icon="getAnswerIcon"
		/>
	</div>
</template>

<script>

	import Hand               from '@/components/diagrams/Hand';
	import CheckboxField      from '@/components/forms/CheckboxField';
	import Compass            from '@/components/diagrams/Compass';
	import AnswerIcon         from '@/components/activities/view/activityTypes/quiz/AnswerIcon';
	import diagramFormat      from '@/mixins/diagramFormat';
	import quizAnswer         from '@/mixins/quizAnswer';
	import quizAnswerIcon     from '@/mixins/quizAnswerIcon';
	import gameService        from '@/services/gameService';
	import { seatPositions }  from '@/consts';

	export default {
		components: {
			Hand,
			CheckboxField,
			Compass,
			AnswerIcon
		},
		mixins: [
			diagramFormat,
			quizAnswer,
			quizAnswerIcon
		],
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			},
			gameState: {
				type: [Object, Boolean],
				default: undefined
			},
			isSimulatorRemote: {
				type: Boolean,
				default: false
			},
			isPartOfQuiz: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			seatPositions
		}),
		computed: {
			getHands () {
				if (this.gameState) {
					return this.getDeal;
				}
				return {};
			},
			getDiagramIsInModal () {
				return this.$store.getters['quiz/getDiagramIsInModalByUuid']({
					uuid: this.uuid
				});
			},
			getPositions () {
				return this.seatPositions.map((seat) => {
					return seat.text.toLowerCase();
				});
			},
			getGame () {
				if (!this.gameState) {
					return false;
				}
				return gameService.getGameInstanceFromGameState({
					gameState: {
						name: 'Deal Matrix',
						deal: `${this.gameState.deal}:`,
						auction: {
							value: this.gameState.auction.value,
							bids: this.gameState.auction.bids
						}
					}
				});
			},
			getHideEmptySuits: {
				get () {
					return this.$store.getters['quiz/getHideEmptySuitsByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setHideEmptySuitsByUuid', {
						uuid: this.uuid,
						hideEmptySuits: value
					});
				}
			}
		},
		mounted () {
		},
		methods: {
			getPositionByPosition (position) {
				if (this.isSimulatorRemote) {
					return false;
				}
				return position;
			},
			getTablePositionByPosition (position) {
				if (!this.isSimulatorRemote) {
					return false;
				}
				return this.$store.getters['simulator/getTablePositionByPosition']({
					position: position[0].toUpperCase()
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-matrix'] {
		width:auto;
		&[data-answer] {
			position:relative;
			padding:16px;
			border-radius:4px;
			border:3px solid transparent;
			&[data-selectable] {
				cursor:pointer;
			}
			&[data-selected] {
				background-color:lighten($c-brand-blue-lighter-1, 50%);
				border-color:$c-brand-blue-lighter-1;
				cursor:default;
			}
			&[data-selected-and-correct-and-quiz-is-complete] {
				background-color:lighten($c-brand-green, 50%);
				border-color:$c-brand-green;
			}
			&[data-selected-and-incorrect-and-quiz-is-complete] {
				background-color:lighten($c-brand-red, 50%);
				border-color:$c-brand-red;
				&[data-selectable] {
					cursor:pointer;
				}
			}
			&[data-highlighted] {
				border-color:$c-brand-green;
			}
			[data-component='answer-icon'] {
				position:absolute;
				bottom:rem(4);
				right:rem(4);
			}
		}
		[data-element='positions'] {
			display:grid;
			grid-template-columns:fit-content(33%) fit-content(33%) auto;
			grid-template-rows:fit-content(33%) fit-content(33%) auto;
			[data-element='position'] {
				display:flex;
				margin:0;
				&[data-position='north'],
				&[data-table-position='top']{
					grid-column-start:2;
					grid-column-end:2;
					grid-row-start:1;
					grid-row-end:1;
					padding-bottom:rem(16);
				}
				&[data-position='east'],
				&[data-table-position='right']{
					grid-column-start:3;
					grid-column-end:3;
					grid-row-start:2;
					grid-row-end:2;
					justify-content:flex-start;
					padding-left:rem(16);
				}
				&[data-position='south'],
				&[data-table-position='bottom']{
					grid-column-start:2;
					grid-column-end:2;
					grid-row-start:3;
					grid-row-end:3;
					justify-self:center;
					align-self:start;
					padding-top:rem(16);
				}
				&[data-position='west'],
				&[data-table-position='left']{
					grid-column-start:1;
					grid-column-end:1;
					grid-row-start:2;
					grid-row-end:2;
					padding-right:rem(16);
				}
			}
		}
	}

</style>
