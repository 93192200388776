<template>
	<div data-component="diagrams">
		<diagram
			v-for="(diagram, key) in getDiagrams"
			:key="key"
			:uuid="diagram.uuid"
			:context="context"
			:label="getLabel(key)"
			:isAnswerDiagram="context === 'answer'"
			:mustChooseAllCorrectAnswers="mustChooseAllCorrectAnswers"
			:correctAnswersAreHighlighted="correctAnswersAreHighlighted"
		/>
	</div>
</template>

<script>

	import Diagram from '@/components/diagrams/Diagram';

	export default {
		components: {
			Diagram
		},
		props: {
			context: {
				type: String,
				default: 'question'
			},
			diagrams: {
				type: Array,
				default: undefined
			},
			mustChooseAllCorrectAnswers: {
				type: Boolean,
				default: false
			},
			correctAnswersAreHighlighted: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getDiagrams () {
				return this.$store.getters['quiz/getDiagramsByContext']({
					context: this.context
				});
			}
		},
		mounted () {},
		methods: {
			getLabel (index) {
				// Removed code to add A, B, C etc
				// if (!this.type ||
				// 		this.type === 'question') {
				// 	return undefined;
				// }
				// return String.fromCharCode(index + 97);
				return undefined;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='diagrams'] {
		display:flex;
		align-items:flex-start;
		justify-content:center;
		flex-wrap:wrap;
		[data-component='diagram'] {
			padding:rem(8);
		}
	}

</style>
