<template>
	<div data-route>
		<div data-element="main">
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
			/>
			<activity
				:isPreview="true"
				entryRoute="admin"
			/>
		</div>
		<portal
			to="modal"
			v-if="deleteConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleDeleteConfirmation"
				heading="Delete activity"
			>
				<confirmation-modal
					text="Deleting the activity will permanently remove it from the lesson."
					confirmationText="Delete"
					confirmationActionId="deleteActivity"
					cancelActionId="toggleDeleteConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import Activity           from '@/components/activities/view/Activity';
	import Actions            from '@/components/ui/Actions';
	import ModalInner         from '@/components/ui/modal/ModalInner';
	import ConfirmationModal  from '@/components/ui/modal/ConfirmationModal';
	import actionClick        from '@/mixins/actionClick';
	import routeParams        from '@/mixins/routeParams';
	import api                from '@/services/api';

	export default {
		metaInfo () {
			if (!this.getActivityTitle) {
				return false;
			}
			return {
				title: this.getActivityTitle
			};
		},
		components: {
			Activity,
			Actions,
			ModalInner,
			ConfirmationModal
		},
		mixins: [actionClick, routeParams],
		data: () => ({
			activity: {
				title: ''
			},
			deleteConfirmationIsVisible: false
		}),
		computed: {
			getBreadcrumbRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}/lessons/${this.getLessonId}/activities`;
			},
			getActivityTitle () {
				if (!this.activity) {
					return false;
				}
				return this.activity.title;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Edit',
							route: `${this.getBreadcrumbRoute}/${this.getActivityId}/edit?type=${this.activity.type}`
						}
					],
					secondary: [
						{
							text: 'Delete activity',
							actionId: 'toggleDeleteConfirmation'
						}
					]
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: 'Course'
					},
					{
						path: this.getLessonRoute,
						text: 'Lesson'
					},
					{
						path: this.getActivityPreviewRoute,
						text: this.getActivityTitle
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setActivity();
		},
		methods: {
			async setActivity () {
				const activity = await api.admin.getActivityByIdAndLessonId({
					lessonId: this.getLessonId,
					activityId: this.getActivityId
				});
				if (!activity) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.activity = activity;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			toggleDeleteConfirmation () {
				this.deleteConfirmationIsVisible = !this.deleteConfirmationIsVisible;
			},
			async deleteActivity () {
				const response = await api.admin.deleteActivityByIdAndLessonId({
					activityId: this.getActivityId,
					lessonId: this.getLessonId
				});
				if (!response) {
					return false;
				}
				await this.$router.push(this.getLessonRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Activity has been deleted'
				});
			}
		}

	};

</script>

<style lang="scss" scoped>
	[data-element='preview'] {
		display: block;
		width: 100%;
	}
</style>
