<template>
	<div
		data-component="suit"
	>
		<icon
			v-if="getShowIcon"
			:icon="getSuitIcon"
			:colour="getSuitColour"
		/>
		<div
			v-if="getShowPreview"
			data-element="cards"
		>
			<div
				data-element="card"
				v-for="(card, key) in getCards"
				:key="key"
				:data-clickable="isSimulatorRemote"
				@click="clickCard(card)"
			>
				{{ getCard(card) }}
			</div>
		</div>
		<suit-editor
			v-if="getShowEditor"
			:uuid="uuid"
			:position="position"
			:suit="suit"
			:isPartOfDealMatrix="isPartOfDealMatrix"
			:isPartOfQuiz="isPartOfQuiz"
		/>
	</div>
</template>

<script>

	import SuitEditor     from '@/components/diagrams/SuitEditor';
	import Icon           from '@/components/ui/Icon';

	export default {
		components: {
			SuitEditor,
			Icon
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			position: {
				type: String,
				default: 'north'
			},
			suit: {
				type: String,
				default: undefined
			},
			cards: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isPartOfDealMatrix: {
				type: Boolean,
				default: false
			},
			isPartOfQuiz: {
				type: Boolean,
				default: false
			},
			isSimulatorRemote: {
				type: Boolean,
				default: false
			},
			hideEmptySuits: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getDiagram () {
				return this.$store.getters['quiz/getDiagramByUuid']({
					uuid: this.uuid
				});
			},
			getSimulatorCards () {
				return this.$store.getters['simulator/getDealMatrixCardsByPositionAndSuit']({
					position: this.position,
					suit: this.suit
				});
			},
			getQuizCards () {
				return this.$store.getters['quiz/getFormattedCardsByUuidAndPositionAndSuit']({
					uuid: this.uuid,
					position: this.position,
					suit: this.suit
				});
			},
			getCards () {
				if (this.isSimulatorRemote) {
					return this.getSimulatorCards;
				}
				if (this.isPartOfQuiz) {
					return this.getQuizCards;
				}
				return this.getSanitizedCards;
			},
			getSanitizedCards () {
				if (!this.cards) {
					return false;
				}
				return this.cards.split(',').map((card) => {
					return card.slice(0, -1);
				});
			},
			getShowIcon () {
				if (this.isEditMode) {
					return true;
				}
				if (!this.getCards && this.hideEmptySuits) {
					return false;
				}
				return true;
			},
			getShowPreview () {
				if (this.isEditMode) {
					return false;
				}
				if (!this.getCards) {
					return false;
				}
				return true;
			},
			getShowEditor () {
				return this.isEditMode;
			},
			getSuitIcon () {
				if (!this.suit) {
					return '';
				}
				return this.suit.slice(0, -1);
			},
			getSuitColour () {
				if (!this.suit) {
					return '';
				}
				if (this.suit.toLowerCase() === 'clubs' ||
						this.suit.toLowerCase() === 'spades') {
					return 'black';
				}
				return 'red';
			},
			getShortSuitName () {
				if (!this.suit) {
					return false;
				}
				return this.suit[0].toUpperCase();
			}
		},
		watch: {},
		mounted () {},
		methods: {
			getCard (card) {
				if (card === 'T') {
					return '10';
				}
				return card;
			},
			getFullCardName (card) {
				if (!this.getShortSuitName ||
						!card) {
					return false;
				}
				return `${card}${this.getShortSuitName}`;
			},
			clickCard (card) {
				if (!this.isSimulatorRemote) {
					return false;
				}
				this.$store.dispatch('simulator/playCardByName', {
					cardName: this.getFullCardName(card)
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='suit'] {
		display:flex;
		align-items:center;
		margin-bottom:rem(4);
		&:last-child {
			margin-bottom:0;
		}
		[data-component='icon'] {
			width:auto;
			height:rem(15);
			margin:0 rem(2) 0 0;
		}
		[data-element='cards'] {
			display:flex;
			align-items:center;
			flex-wrap:nowrap;
			[data-element='card'] {
				display:flex;
				align-items:center;
				flex-wrap:nowrap;
				height:rem(20);
				margin:0 rem(2) 0 0;
				font-size:rem(18);
				font-weight:700;
				&[data-clickable] {
					cursor:pointer;
				}
			}
		}
	}
</style>
