export default {
	computed: {
		getIsSelectedAndCorrectAndQuizIsComplete () {
			return this.$store.getters['quiz/getAnswerIsSelectedAndCorrectByUuidAndQuizIsComplete']({
				uuid: this.uuid
			});
		},
		getIsSelectedAndIncorrectAndQuizIsComplete () {
			return this.$store.getters['quiz/getAnswerIsSelectedAndIncorrectByUuidAndQuizIsComplete']({
				uuid: this.uuid
			});
		},
		getAnswerIcon () {
			if (!this.isAnswerDiagram) {
				return false;
			}
			if (this.getIsSelectedAndIncorrectAndQuizIsComplete) {
				return 'cross-circle';
			}
			if (this.getIsSelectedAndCorrectAndQuizIsComplete) {
				return 'check';
			}
			return false;
		}
	}
};
