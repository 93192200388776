<template>
	<div data-component="title-page">
		<div data-element="wrapper">
			<h1
				data-element="heading"
			>
				{{ this.getHeading }}
			</h1>
			<div
				v-if="getText"
				data-element="text"
				v-html="getText"
			/>
		</div>
		<activity-buttons
			:isPreview="isPreview"
		/>
	</div>
</template>

<script>

	import ActivityButtons from '@/components/activities/view/ActivityButtons';

	export default {
		components: {
			ActivityButtons
		},
		props: {
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getHeading () {
				return this.$store.getters['lesson/getActivityIntertitleHeading'];
			},
			getText () {
				return this.$store.getters['lesson/getActivityIntertitleText'];
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='title-page'] {
		[data-element='wrapper'] {
			[data-element='heading'] {
				margin:0 0 rem(24);
				color:$c-brand-blue;
				font-size:rem(48);
				font-weight:700;
				text-align:center;
			}
			::v-deep [data-element='text'] {
				font-size:rem(22);
				text-align:center;
				@include rich-text-content;
				@include rich-text-suits;
			}
		}
	}

</style>
