<template>
	<div data-component="bidding-interface">
		<auction
			:uuid="uuid"
			:isEditMode="isEditMode"
			:isThumbnail="isThumbnail"
			:isAnswerDiagram="true"
			:isValid="isValid"
			:errors="errors"
			:isPartOfQuiz="true"
		/>
		<bidding-interface-editor
			v-if="isEditMode"
			:uuid="uuid"
		/>
		<answer-icon
			v-if="getAnswerIcon"
			:icon="getAnswerIcon"
		/>
	</div>
</template>

<script>

	import Auction                 from '@/components/simulator/bidding/Auction';
	import BiddingInterfaceEditor  from '@/components/diagrams/BiddingInterfaceEditor';
	import AnswerIcon              from '@/components/activities/view/activityTypes/quiz/AnswerIcon';
	import quizAnswerIcon          from '@/mixins/quizAnswerIcon';

	export default {
		components: {
			Auction,
			BiddingInterfaceEditor,
			AnswerIcon
		},
		mixins: [quizAnswerIcon],
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
		}),
		computed: {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='bidding-interface'] {
		position:relative;
		[data-component='answer-icon'] {
			position:absolute;
			bottom:rem(4);
			right:rem(4);
		}
	}

</style>
