<template>
	<div data-component="compass">
		<div
			v-for="(position, key) in positions"
			data-element="compass-point"
			:key="key"
			:data-position="getPositionByPosition(position)"
			:data-table-position="getTablePositionByPosition(position)"
		>
			{{ position.value.toUpperCase() }}
		</div>
	</div>
</template>

<script>

	import { seatPositions }  from '@/consts';

	export default {
		props: {
			isSimulatorRemote: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			positions: seatPositions
		}),
		methods: {
			getPositionByPosition (position) {
				if (this.isSimulatorRemote) {
					return false;
				}
				return position.text.toLowerCase();
			},
			getTablePositionByPosition (position) {
				if (!this.isSimulatorRemote) {
					return false;
				}
				return this.$store.getters['simulator/getTablePositionByPosition']({
					position: position.value
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='compass'] {
		position: relative;
		grid-column-start: 2;
		grid-column-end: 2;
		grid-row-start: 2;
		grid-row-end: 2;
		justify-self: center;
		align-self: center;
		width: 64px;
		height: 64px;
		[data-element='compass-point'] {
			position: absolute;
			font-size: rem(12);
			&[data-position='north'],
			&[data-table-position='top']{
				top: 8px;
				left: 50%;
				transform: translate(-50%, 0);
			}
			&[data-position='east'],
			&[data-table-position='right']{
				right: 8px;
				top: 50%;
				transform: translate(0, -50%);
			}
			&[data-position='south'],
			&[data-table-position='bottom']{
				left: 50%;
				bottom: 8px;
				transform: translate(-50%, 0);
			}
			&[data-position='west'],
			&[data-table-position='left']{
				left: 8px;
				top: 50%;
				transform: translate(0, -50%);
			}
		}
	}
</style>
