<template>
	<div
		data-component="opening-lead"
	>
		<opening-lead-editor
			v-if="getShowEditor"
			v-model="getOpeningLead"
			:deal="deal"
		/>
		<div
			v-if="getShowPreview"
			data-element="preview"
		>
			<div
				data-element="card-value"
				v-if="getCardValue"
			>
				{{ getCardValue }}
			</div>
			<suit-symbol
				v-if="getSuitName"
				:suitName="getSuitName"
				:withoutPadding="true"
			/>
		</div>
	</div>
</template>

<script>

	import OpeningLeadEditor  from '@/components/diagrams/OpeningLeadEditor';
	import SuitSymbol         from '@/components/diagrams/SuitSymbol';

	export default {
		components: {
			OpeningLeadEditor,
			SuitSymbol
		},
		props: {
			value: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isSimulatorRemote: {
				type: Boolean,
				default: false
			},
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({}),
		computed: {
			getOpeningLead: {
				get () {
					return this.value;
				},
				set (newVal) {
					this.$emit('input', newVal);
				}
			},
			getShowIcon () {
				if (!this.getOpeningLead) {
					return false;
				}
				return true;
			},
			getShowPreview () {
				if (this.isEditMode) {
					return false;
				}
				return true;
			},
			getShowEditor () {
				return this.isEditMode;
			},
			getSuitColour () {
				if (!this.getSuitName) {
					return '';
				}
				if (this.getSuitName === 'clubs' ||
						this.getSuitName === 'spades') {
					return 'black';
				}
				return 'red';
			},
			getCardValue () {
				if (!this.getOpeningLead ||
						this.getOpeningLead.length < 1) {
					return false;
				}
				return this.getOpeningLead[0];
			},
			getSuitName () {
				if (!this.getOpeningLead ||
						this.getOpeningLead.length < 2) {
					return false;
				}
				return this.getOpeningLead[1];
			}
		},
		watch: {},
		mounted () {},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='opening-lead'] {
		[data-element='preview'] {
			display:flex;
			align-items:center;
			margin:0;
			[data-element='card-value'] {
				font-size:rem(14);
				line-height:1;
				font-weight:700;
			}
			::v-deep [data-component='suit-symbol'] {
				margin-left:0.1em;
				[data-component='icon'] {
					width:auto; height:rem(10);
				}
			}
		}
	}
</style>
