<template>
	<div data-component="bidding-table-editor">
		<validation-observer
			data-element="fields"
			tag="div"
			ref="innerdiagram"
		>
			<select-field
				name="Dealer"
				rules="required"
				:id="getFieldId('dealer-position')"
				:options="dealerPositionOptions"
				:showLabels="false"
				v-model="getDealerPosition"
			/>
			<input-field
				name="Bids"
				type="text"
				autocomplete="off"
				:rules="getAuctionValidation"
				:id="getFieldId('bids')"
				v-model="getBids"
			/>
			<select-field
				name="Show symbol after bids"
				:showLabels="false"
				:emptyAllowed="false"
				:options="postSymbolOptions"
				v-model="getPostSymbol"
			/>
			<checkbox-field
				v-if="isAnswerDiagram"
				name="Correct answer"
				:id="`check-${uuid}`"
				v-model="getIsCorrect"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import SelectField        from '@/components/forms/SelectField';
	import InputField         from '@/components/forms/InputField';
	import CheckboxField      from '@/components/forms/CheckboxField';
	import { seatPositions }  from '@/consts';

	export default {
		components: {
			SelectField,
			CheckboxField,
			InputField
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			dealerPositionOptions:	seatPositions,
			postSymbolOptions: [
				{
					text: 'None',
					value: 'none'
				},
				{
					text: 'Question mark (?)',
					value: 'questionMark'
				},
				{
					text: 'Ellipsis (…)',
					value: 'ellipsis'
				}
			]
		}),
		computed: {
			getBids: {
				get () {
					return this.$store.getters['quiz/getBidsByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setBidsByUuid', {
						uuid: this.uuid,
						bids: this.sanitiseBids(value)
					});
				}
			},
			getDealerPosition: {
				get () {
					return this.$store.getters['quiz/getDealerPositionByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setDealerPositionByUuid', {
						uuid: this.uuid,
						dealerPosition: value
					});
				}
			},
			getPostSymbol: {
				get () {
					return this.$store.getters['quiz/getPostSymbolByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setPostSymbolByUuid', {
						uuid: this.uuid,
						postSymbol: value
					});
				}
			},
			getIsCorrect: {
				get () {
					return this.$store.getters['quiz/getIsCorrectByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setIsCorrectByUuid', {
						uuid: this.uuid,
						isCorrect: value
					});
				}
			},
			getAuctionValidation () {
				return {
					bids: {
						dealer: this.getDealerPosition
					}
				};
			}
		},
		watch: {},
		mounted () {},
		methods: {
			sanitiseBids (bids) {
				return bids.toUpperCase().replace(' ', ',');
			},
			getFieldId (field) {
				return `${this.uuid}-${field}`;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bidding-table-editor'] {
	}

</style>
