export default {
	props: {},
	computed: {
		getDiagramIsInModal () {
			return this.$store.getters['quiz/getDiagramIsInModalByUuid']({
				uuid: this.uuid
			});
		},
		getQuizIsComplete () {
			return this.$store.getters['quiz/getQuizIsComplete'];
		},
		getIsCorrect: {
			get () {
				return this.$store.getters['quiz/getIsCorrectByUuid']({
					uuid: this.uuid,
					answerValue: this.getAnswerValue
				});
			},
			set (value) {
				return this.$store.commit('quiz/setIsCorrectByUuid', {
					uuid: this.uuid,
					isCorrect: value
				});
			}
		},
		getIsSelectable () {
			if (this.isThumbnail) {
				return false;
			}
			if (this.getCorrectAnswersAreHighlighted) {
				return false;
			}
			if (this.isEditMode) {
				return false;
			}
			if (!this.isAnswerDiagram) {
				return false;
			}
			if (this.getDiagramIsInModal) {
				return false;
			}
			if (this.getQuizIsCompleteAndCorrect) {
				return false;
			}
			return true;
		},
		getCorrectAnswersAreHighlighted () {
			return this.$store.getters['quiz/getCorrectAnswersAreHighlighted'];
		},
		getIsHighlighted () {
			return this.$store.getters['quiz/getAnswerIsHighlightedByUuidAndAnswerValue']({
				uuid: this.uuid,
				answerValue: this.getAnswerValue
			});
		},
		getIsSelected () {
			return this.$store.getters['quiz/getAnswerIsSelectedByUuidAndAnswerValue']({
				uuid: this.uuid,
				answerValue: this.getAnswerValue
			});
		},
		getIsSelectedAndCorrect () {
			return this.$store.getters['quiz/getAnswerIsSelectedAndCorrectByUuidAndAnswerValue']({
				uuid: this.uuid,
				answerValue: this.getAnswerValue
			});
		},
		getIsSelectedAndIncorrect () {
			return this.$store.getters['quiz/getAnswerIsSelectedAndIncorrectByUuidAndAnswerValue']({
				uuid: this.uuid,
				answerValue: this.getAnswerValue
			});
		},
		getIsSelectedAndCorrectAndQuizIsComplete () {
			return this.$store.getters['quiz/getAnswerIsSelectedAndCorrectByUuidAndAnswerValueAndQuizIsComplete']({
				uuid: this.uuid,
				answerValue: this.getAnswerValue
			});
		},
		getIsSelectedAndIncorrectAndQuizIsComplete () {
			return this.$store.getters['quiz/getAnswerIsSelectedAndIncorrectByUuidAndAnswerValueAndQuizIsComplete']({
				uuid: this.uuid,
				answerValue: this.getAnswerValue
			});
		},
		getAnswerValue () {
			// default for clicking entire diagram
			return this.uuid;
		},
		getQuizIsCompleteAndCorrect () {
			return this.$store.getters['quiz/getQuizIsCompleteAndCorrect'];
		},
		getQuizIsCompleteButIncorrect () {
			return this.$store.getters['quiz/getQuizIsCompleteButIncorrect'];
		}
	},
	methods: {
		selectAnswer () {
			if (!this.getIsSelectable) {
				return false;
			}
			if (this.getQuizIsCompleteButIncorrect) {
				this.$store.commit('quiz/unhighlightCorrectAnswers');
				this.$store.commit('quiz/clearSelectedAnswers');
				this.$store.dispatch('lesson/setActivityProgress', {
					progress: null
				});
			}
			this.$nextTick(() => {
				this.$store.dispatch('quiz/selectAnswerByUuidAndAnswerValue', {
					uuid: this.uuid,
					answerValue: this.getAnswerValue,
					clearSelectedAnswersFirst: this.getQuizIsCompleteButIncorrect
				});
			});
		}
	}
};
