<template>
	<div data-component="deal">
		<component
			:is="getComponent"
			v-bind="getProps"
		/>
	</div>
</template>

<script>

	import DealSimulator  from '@/components/activities/view/activityTypes/deal/DealSimulator';
	import DealView       from '@/components/activities/view/activityTypes/deal/DealView';

	export default {
		components: {
			DealSimulator,
			DealView
		},
		props: {
			// config: {
			// 	type: Object,
			// 	default: () => ({
			// 		gameState: undefined,
			// 		fileName: undefined
			// 	})
			// },
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getActivity () {
				return this.$store.getters['lesson/getActivity'];
			},
			getLessonIsLive () {
				return this.$store.getters['lesson/getLessonIsLive'];
			},
			getComponent () {
				if (!this.isPreview) {
					return 'DealSimulator';
				}
				return 'DealView';
			},
			getProps () {
				return {
					config:            this.getActivity?.config,
					isPreview:         this.isPreview
				};
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal'] {}

</style>
