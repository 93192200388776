import { render, staticRenderFns } from "./DealView.vue?vue&type=template&id=96ba8daa&scoped=true&"
import script from "./DealView.vue?vue&type=script&lang=js&"
export * from "./DealView.vue?vue&type=script&lang=js&"
import style0 from "./DealView.vue?vue&type=style&index=0&id=96ba8daa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96ba8daa",
  null
  
)

export default component.exports