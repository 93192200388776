<template>
	<div
		data-component="activity"
		:data-is-lesson="isLesson"
	>
		<component
			v-if="getActivityComponent"
			:is="getActivityComponent"
			:key="getActivity.id"
			:isPreview="isPreview"
		/>
	</div>
</template>

<script>

	import TitlePage       from '@/components/activities/view/activityTypes/TitlePage';
	import Quiz            from '@/components/activities/view/activityTypes/Quiz';
	import Media           from '@/components/activities/view/activityTypes/Media';
	import Deal            from '@/components/activities/view/activityTypes/Deal';
	import routeParams     from '@/mixins/routeParams';

	export default {
		components: {
			Quiz,
			Media,
			Deal,
			TitlePage
		},
		mixins: [routeParams],
		props: {
			isLesson: {
				type: Boolean,
				default: false
			},
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getActivity () {
				return this.$store.getters['lesson/getActivity'];
			},
			getCourseTitle () {
				return this.$store.getters['lesson/getCourseTitle'];
			},
			getLessonIsLive () {
				return this.$store.getters['lesson/getLessonIsLive'];
			},
			getLessonTitle () {
				return this.$store.getters['lesson/getLessonTitle'];
			},
			getActivityTitle () {
				return this.$store.getters['lesson/getActivityTitle'];
			},
			getActivityComponent () {
				return this.$store.getters['lesson/getActivityComponent'];
			},
			getActivityMediaComponent () {
				return this.$store.getters['lesson/getActivityMediaComponent'];
			},
			getTourStep () {
				if (this.getActivityMediaComponent !== 'PresentationViewer') {
					return false;
				}
				return {
					target: '[data-tour-id="presentationViewer"]',
					content: 'Use the arrow buttons to move presentation forward or back',
					tourId: 'presentationViewer',
					params: {
						placement: 'top',
						modifiers: [
							{
								name: 'flip',
								options: {
									fallbackPlacements: []
								}
							}
						]
					}
				};
			},
			getBreadcrumbs () {
				if (this.getLessonIsLive) {
					return [];
				}
				return [
					(this.getContext === 'admin' ?
						{
							path: '/admin',
							text: 'Admin'
						} : false
					),
					(this.getContext === 'teaching' ?
						{
							path: '/teaching',
							text: 'Teaching'
						} : false
					),
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.getCourseTitle ? this.getCourseTitle : 'Course'
					},
					{
						path: this.getLessonRoute,
						text: this.getLessonTitle ? this.getLessonTitle : 'Lesson'
					},
					(!this.isCreate ?
						{
							path: this.getActivityRoute,
							text: this.getActivityTitle
						} : false
					)
				].filter(Boolean);
			}
		},
		created () {
			this.setActivity();
		},
		methods: {
			async setActivity () {
				await this.$store.dispatch('lesson/setActivity', {
					context: this.getContext,
					courseId: this.getCourseId,
					lessonId: this.getLessonId,
					activityId: this.getActivityId
				});
				this.$store.commit('ui/addTourSteps', {
					tourSteps: [
						this.getTourStep
					].filter(Boolean)
				});
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='activity'] {
		&[data-is-lesson] {
			@include grid-container;
			::v-deep [data-component='quiz'] {
				@include grid;
				margin-top:rem(128);
				padding-bottom:rem(112);
				[data-element='quiz-content'] {
					@include cols(12);
				}
			}
			::v-deep [data-component='media'] {
				@include grid;
				margin-top:rem(128);
				padding-bottom:rem(64);
				[data-component='presentation-viewer'],
				[data-component='video-viewer'] {
					@include cols(12);
					@include mq('sm') {
						@include cols(10);
						@include push(1);
					}
				}
			}
			::v-deep [data-component='title-page'] {
				@include grid;
				margin-top:rem(128);
				padding-bottom:rem(64);
				[data-element='wrapper'] {
					@include cols(12);
					@include mq('sm') {
						@include cols(10);
						@include push(1);
					}
				}
			}
		}
	}

</style>
