<template>
	<div data-component="confirmation-modal">
		<p
			data-element="text"
			v-if="text"
		>
			{{ text }}
		</p>
		<actions
			v-if="getActions"
			:actions="getActions"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import Actions from '@/components/ui/Actions';

	export default {
		components: {
			Actions
		},
		props: {
			text: {
				type: String,
				default: undefined
			},
			confirmationText: {
				type: String,
				default: undefined
			},
			confirmationActionId: {
				type: String,
				default: undefined
			},
			cancelActionId: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getActions () {
				return {
					primary: [
						(this.confirmationText ?
							{
								text: this.confirmationText,
								actionId: this.confirmationActionId,
								colour: 'red'
							} : false
						)
					].filter(Boolean),
					secondary: [
						(this.confirmationText ?
							{
								text: 'Cancel',
								actionId: this.cancelActionId
							} : false
						)
					].filter(Boolean)
				};
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='confirmation-modal'] {
		[data-element='text'] {
			margin:0 0 rem(12);
			font-size:rem(18);
			line-height:1.4;
		}
		[data-element='actions'] {
		}
	}

</style>
