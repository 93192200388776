<template>
	<p
		data-component="counter"
		:data-align="align"
		v-html="getString"
	/>
</template>

<script>

	export default {
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			position: {
				type: String,
				default: 'north'
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			},
			align: {
				type: String,
				default: 'centre'
			}
		},
		data: () => ({}),
		computed: {
			getError () {
				const errorArray = [];
				for (const field in this.errors) {
					if (!field.includes(this.position)) {
						continue;
					}
					if (!this.errors[field].length) {
						continue;
					}
					for (const error in this.errors[field]) {
						errorArray.push(this.errors[field][error]);
					}
				}
				if (!errorArray.length) {
					return false;
				}
				return errorArray[0];
			},
			getHand () {
				return this.$store.getters['quiz/getHandByUuidAndPosition']({
					uuid: this.uuid,
					position: this.position
				});
			},
			getString () {
				if (this.getError) {
					return this.getError;
				}
				if (this.getNumberOfCardsRemaining === false ||
						this.getNumberOfCardsRemaining === 13) {
					return 'Hand is empty';
				}
				if (!this.getNumberOfCardsRemaining) {
					return 'This hand is full';
				}
				if (this.getNumberOfCardsRemaining === 0) {
					return 'Hand is full';
				}
				if (this.getNumberOfCardsRemaining < 0) {
					return 'Hand has too many cards';
				}
				return `Hand has <span data-element="number">${this.getNumberOfCardsRemaining}</span> cards left`;
			},
			getNumberOfCardsRemaining () {
				if (!this.getNumberOfCardsInHand) {
					return false;
				}
				return 13 - this.getNumberOfCardsInHand;
			},
			getNumberOfCardsInHand () {
				return this.$store.getters['quiz/getNumberOfCardsInHandByUuidAndPosition']({
					uuid: this.uuid,
					position: this.position
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='counter'] {
		font-size:rem(18);
		text-align: center;
		&[data-align='left'] {
			text-align: left;
		}
		::v-deep [data-element='number'] {
			font-weight:700;
			color:$c-red;
		}
	}

</style>
