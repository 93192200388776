<template>
	<div data-component="media">
		<component
			v-if="getMediaUrl"
			:is="getComponent"
			:src="getMediaUrl"
		/>
		<div data-element="presentation-tour-wrapper">
			<div data-element="presentation-tour" :data-tour-id="getTourId" />
		</div>
		<activity-buttons
			:isPreview="isPreview"
		/>
	</div>
</template>

<script>

	import PresentationViewer  from '@/components/uploads/PresentationViewer';
	import VideoViewer         from '@/components/uploads/VideoViewer';
	import ActivityButtons     from '@/components/activities/view/ActivityButtons';
	import { camelCase }       from 'change-case';

	export default {
		components: {
			ActivityButtons,
			PresentationViewer,
			VideoViewer
		},
		props: {
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getComponent () {
				return this.$store.getters['lesson/getActivityMediaComponent'];
			},
			getMediaUrl () {
				return this.$store.getters['lesson/getActivityMediaUrl'];
			},
			getActivityComponent () {
				return this.$store.getters['lesson/getActivityComponent'];
			},
			getActivityMediaComponent () {
				return this.$store.getters['lesson/getActivityMediaComponent'];
			},
			getTourId () {
				if (!this.getActivityComponent) {
					return false;
				}
				if (this.getActivityComponent !== 'Media') {
					return false;
				}
				if (!this.getActivityMediaComponent) {
					return false;
				}
				return camelCase(this.getActivityMediaComponent);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='media'] {
		[data-component='presentation-viewer'],
		[data-component='video-viewer'] {
			margin-bottom:rem(24);
		}
		[data-element='presentation-tour-wrapper'] {
			display:flex;
			justify-content:center;
			width:100%;
			height:0;
			margin-top:rem(-48);
			[data-element='presentation-tour'] {
			}
			@include cols(12);
			@include mq('sm') {
				@include cols(10);
				@include push(1);
			}
		}
	}

</style>
