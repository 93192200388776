<template>
	<div
		data-component="diagram"
	>
		<div
			v-if="getLabel"
			data-element="label"
		>
			{{ getLabel }}
		</div>
		<component
			:is="getComponent"
			:uuid="uuid"
			:isEditMode="isEditMode"
			:isValid="isValid"
			:errors="errors"
			:isThumbnail="isThumbnail"
			:isPartOfQuiz="true"
			:isAnswerDiagram="isAnswerDiagram"
		/>
	</div>
</template>

<script>

	import BiddingTable      from '@/components/diagrams/BiddingTable';
	import Hand              from '@/components/diagrams/Hand';
	import DealMatrix        from '@/components/diagrams/DealMatrix';
	import BiddingInterface  from '@/components/diagrams/BiddingInterface';
	import TextAnswer        from '@/components/diagrams/TextAnswer';

	export default {
		components: {
			BiddingTable,
			Hand,
			DealMatrix,
			BiddingInterface,
			TextAnswer
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			},
			label: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getDiagram () {
				return this.$store.getters['quiz/getDiagramByUuid']({
					uuid: this.uuid
				});
			},
			getLabel () {
				if (!this.label) {
					return false;
				}
				return this.label;
			},
			getType () {
				if (!this.getDiagram) {
					return false;
				}
				return this.getDiagram.type;
			},
			getComponent () {
				if (!this.getType) {
					return false;
				}
				return this.getType;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='diagram'] {
		[data-element='label'] {
			display:flex;
			align-items:center;
			justify-content:center;
			width:rem(16);
			height:rem(16);
			margin:0;
			background-color:$c-white;
			border-radius:100%;
			border:1px solid $c-brand-grey-lighter-2;
			font-size:rem(10);
			line-height:1;
			text-align:center;
			font-weight:700;
		}
	}

</style>
