import { paramCase } from 'change-case';

export default {
	computed: {
		getContext () {
			if (this.$route.path.includes('teaching')) {
				return 'teaching';
			}
			if (this.$route.path.includes('admin')) {
				return 'admin';
			}
			if (this.$route.path.includes('my-courses')) {
				return 'myCourses';
			}
			return 'browseCourses';
		},
		getContextSlug () {
			return paramCase(this.getContext);
		},
		getApiRoot () {
			if (this.getContext === 'teaching') {
				return 'teaching';
			}
			if (this.getContext === 'admin') {
				return 'admin';
			}
			return 'courses';
		},
		getCourseId () {
			return parseInt(this.$route.params.courseId);
		},
		getLessonId () {
			return parseInt(this.$route.params.lessonId);
		},
		getActivityId () {
			return parseInt(this.$route.params.activityId);
		},
		getStudentId () {
			return parseInt(this.$route.params.studentId);
		},
		getUserId () {
			return parseInt(this.$route.params.userId);
		},
		getEnrolmentId () {
			return this.$route.params.enrolmentId;
		},
		getCategoryId () {
			return parseInt(this.$route.params.categoryId);
		},
		getArticleId () {
			return parseInt(this.$route.params.articleId);
		},
		getArticleSlug () {
			return this.$route.params.articleSlug;
		},
		getCategorySlug () {
			return this.$route.params.categorySlug;
		},
		getCoursesRoute () {
			if (!this.getContext ||
					!this.getContextSlug) {
				return false;
			}
			if (this.getContext === 'teaching' ||
					this.getContext === 'admin') {
				return `/${this.getContextSlug}/courses`;
			}
			if (this.getContext === 'myCourses') {
				return '/my-courses';
			}
			return '/browse-courses';
		},
		getCourseRoute () {
			if (!this.getCoursesRoute ||
					!this.getCourseId) {
				return false;
			}
			return `${this.getCoursesRoute}/${this.getCourseId}`;
		},
		getLessonRoute () {
			if (!this.getCourseRoute ||
					!this.getLessonId) {
				return false;
			}
			return `${this.getCourseRoute}/lessons/${this.getLessonId}`;
		},
		getActivityPreviewRoute () {
			if (!this.getLessonRoute ||
					!this.getActivityId) {
				return false;
			}
			return `${this.getLessonRoute}/activities/${this.getActivityId}`;
		},
		getActivityRoute () {
			if (!this.getLessonRoute ||
					!this.getActivityId) {
				return false;
			}
			if (this.getContext === 'teaching') {
				return `${this.getLessonRoute}/activities/${this.getActivityId}/view`;
			}
			return `${this.getLessonRoute}/activities/${this.getActivityId}`;
		}
	}
};
