<template>
	<div data-component="text-answer">
		<div
			v-if="!isEditMode"
			data-element="answer-button"
			:data-selected="getIsSelected"
			:data-highlighted="getIsHighlighted"
			:data-answer="isAnswerDiagram"
			:data-selectable="getIsSelectable"
			:data-selected-and-correct-and-quiz-is-complete="getIsSelectedAndCorrectAndQuizIsComplete"
			:data-selected-and-incorrect-and-quiz-is-complete="getIsSelectedAndIncorrectAndQuizIsComplete"
			@click="selectAnswer"
		>
			<div
				data-element="text"
				v-html="getText"
			/>
			<answer-icon
				v-if="getAnswerIcon"
				:icon="getAnswerIcon"
			/>
		</div>
		<div
			v-if="isEditMode"
			data-element="answer-editor"
		>
			<wysiwyg-field
				name="Text"
				type="text"
				rules="required"
				v-model="getText"
				:hasEditOptions="false"
				:hasListControl="false"
				:hasLinkControl="false"
			/>
			<checkbox-field
				name="Correct answer"
				:id="`check-${uuid}`"
				v-model="getIsCorrect"
			/>
		</div>
	</div>
</template>

<script>

	import CheckboxField   from '@/components/forms/CheckboxField';
	import AnswerIcon      from '@/components/activities/view/activityTypes/quiz/AnswerIcon';
	import quizAnswer      from '@/mixins/quizAnswer';
	import quizAnswerIcon  from '@/mixins/quizAnswerIcon';

	export default {
		components: {
			CheckboxField,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			AnswerIcon
		},
		mixins: [quizAnswer, quizAnswerIcon],
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			}
		},
		data: () => ({}),
		computed: {
			getText: {
				get () {
					return this.$store.getters['quiz/getTextByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setTextByUuid', {
						uuid: this.uuid,
						text: value
					});
				}
			}
		},
		watch: {},
		mounted () {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='text-answer'] {
		[data-element='answer-button'] {
			display:flex;
			align-items:center;
			justify-content:center;
			margin:0;
			padding:rem(22) rem(22);
			border:3px solid $c-brand-grey-lighter-3;
			border-radius:4px;
			background-color:$c-white;
			user-select:none;
			::v-deep [data-element='text'] {
				font-size:rem(22);
				font-weight:700;
				@include rich-text-content;
				@include rich-text-suits;
			}
			&[data-answer] {
				position:relative;
				border-radius:4px;
				&[data-selectable] {
					cursor:pointer;
				}
				&[data-selected] {
					background-color:lighten($c-brand-blue-lighter-1, 50%);
					border-color:$c-brand-blue-lighter-1;
					cursor:default;
				}
				&[data-selected-and-correct-and-quiz-is-complete] {
					background-color:lighten($c-brand-green, 50%);
					border-color:$c-brand-green;
					&[data-selectable] {
						cursor:pointer;
					}
				}
				&[data-selected-and-incorrect-and-quiz-is-complete] {
					background-color:lighten($c-brand-red, 50%);
					border-color:$c-brand-red;
					&[data-selectable] {
						cursor:pointer;
					}
				}
				&[data-highlighted] {
					border-color:$c-brand-green;
				}
				[data-component='answer-icon'] {
					position:absolute;
					bottom:rem(4);
					right:rem(4);
				}
			}
		}
	}

</style>
