<template>
	<div
		data-component="answer-icon"
		:data-icon="icon"
	>
		<icon
			v-if="icon"
			data-element="answer-icon"
			:icon="icon"
			:colour="getIconColour"
		/>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			icon: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getIconColour () {
				if (!this.icon) {
					return false;
				}
				if (this.icon === 'cross-circle') {
					return 'red';
				}
				return 'green';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='answer-icon'] {
		width:auto;
		background-color:$c-white;
		border-radius:50%;
		&[data-icon='cross'] {
		}
		&[data-icon='tick'] {
		}
		::v-deep [data-component='icon'] {
			height:rem(32);
			width:auto;
		}
	}

</style>
