<template>
	<div data-component="presentation-viewer">
		<iframe
			v-if="getSrc"
			:src="getSrc"
			frameborder="0"
			allowfullscreen="true"
			mozallowfullscreen="true"
			webkitallowfullscreen="true"
			id="presentation-viewer-iframe"
		/>
	</div>
</template>

<script>

	// default iframe width/height
	// width="1440"
	// height="839"

	export default {
		props: {
			src: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getSrc () {
				if (!this.src) {
					return false;
				}
				const encodedSrc = encodeURIComponent(this.src);
				const embedUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedSrc}`;
				return embedUrl;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='presentation-viewer'] {
		position:relative;
		max-width:100%;
		overflow:hidden;
		&:before {
			content:"";
			display:block;
			width:100%;
			padding-top:calc(((9 / 16) * 100%) + 24px);
		}
		iframe {
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			width:100%;
			height:100%;
		}
	}

</style>
