<template>
	<component
		data-component="activity-button"
		data-type="type"
		:data-flashing="isFlashing"
		:data-inactive="isInactive"
		:data-tour-id="tourId"
		:is="getComponent"
		v-bind="getProps"
		@click="getClick"
	>
		<span
			data-element="text"
		>
			{{ text }}
		</span>
		<icon
			:icon="getIcon"
			:colour="getIconColour"
		/>
	</component>
</template>

<script>

	import Icon    from '@/components/ui/Icon';
	import action  from '@/mixins/action';

	export default {
		components: {
			Icon
		},
		mixins: [action],
		props: {
			text: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				default: undefined
			},
			tourId: {
				type: String,
				default: ''
			},
			isFlashing: {
				type: Boolean,
				default: false
			},
			isInactive: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getIconColour () {
				if (!this.isFlashing) {
					return 'white';
				}
				return 'yellow';
			},
			getIcon () {
				if (!this.type) {
					return false;
				}
				if (this.type === 'next' ||
						this.type === 'finish') {
					return 'chevron-right';
				}
				if (this.type === 'previous') {
					return 'chevron-left';
				}
				return false;
			}
		},
		methods: {
			getClick () {
				if (this.isInactive) {
					return false;
				}
				return this.click();
			}
		}
	};

</script>

<style lang="scss" scoped>

	@keyframes flash {
		from {
			opacity:0.2;
		}
		to {
			opacity:1;
		}
	}

	[data-component='activity-button'] {
		display:flex;
		align-items:center;
		flex-shrink:0;
		flex-grow:0;
		width:auto;
		height:rem(48);
		padding:0;
		border:none;
		border-radius:4px;
		user-select:none;
		cursor:pointer;
		background-color:transparent;
		&[data-inactive] {
			opacity:0.2;
			cursor:default;
		}
		&[data-flashing] {
			animation:ease 600ms infinite flash alternate;
			[data-element='text'] {
				color:yellow;
			}
		}
		[data-element='text'] {
			font-size:rem(18);
			line-height:1;
			font-weight:700;
			color:$c-white;
		}
		[data-component='icon'] {
			height:rem(24);
			width:auto;
		}
		&[data-type='previous'] {
			[data-component='icon'] {
				order:-1;
			}
		}
  }

</style>
