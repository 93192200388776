export default {
	computed: {
		getDeal ()  {
			return {
				...this.getPosition('north'),
				...this.getPosition('east'),
				...this.getPosition('south'),
				...this.getPosition('west')
			};
		}
	},
	methods: {
		getPosition (position) {
			return {
				[position]: {
					...this.getCardsBySuitAndPosition({
						position,
						suit: 'spades'
					}),
					...this.getCardsBySuitAndPosition({
						position,
						suit: 'diamonds'
					}),
					...this.getCardsBySuitAndPosition({
						position,
						suit: 'hearts'
					}),
					...this.getCardsBySuitAndPosition({
						position,
						suit: 'clubs'
					})
				}
			};
		},
		getCardsBySuitAndPosition ({ suit, position }) {
			const shortSuit = suit.charAt(0).toUpperCase();
			return {
				[suit]: this.getCardsByPosition(position).filter((card) => {
					const cardSuit = card.suitName;
					if (cardSuit !== shortSuit) {
						return false;
					}
					return card.name;
				}).join(',')
			};
		},
		getCardsByPosition (position) {
			const shortPosition = position.charAt(0).toUpperCase();
			const playerProp = `player${shortPosition}`;
			if (!this.getGame?.[playerProp]?.hand?.length) {
				return [];
			}
			return this.getGame[playerProp].hand;
		}
	}
};
