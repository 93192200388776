<template>
	<div data-component="suit-editor">
		<input-field
			type="text"
			v-model="getCards"
			:name="getSuitLabel"
			autocomplete="off"
			:ref="getRefName"
			:id="getRefName"
			:rules="getSuitValidationRules"
			size="xs"
			:showLabel="false"
			:showValidation="false"
		/>
	</div>
</template>

<script>

	import InputField         from '@/components/forms/InputField';
	import { sentenceCase }   from 'change-case';
	import { suitOrder }      from '@/consts';
	import { seatPositions }  from '@/consts';

	export default {
		components: {
			InputField
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			suit: {
				type: String,
				default: undefined
			},
			position: {
				type: String,
				default: 'north'
			},
			isPartOfDealMatrix: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			suitOrder
		}),
		computed: {
			getCards: {
				get () {
					return this.$store.getters['quiz/getCardsByUuidAndPositionAndSuit']({
						uuid:      this.uuid,
						suit:      this.suit,
						position:  this.position
					});
				},
				set (value) {
					return this.$store.commit('quiz/setCardsByUuidAndPositionAndSuit', {
						uuid:      this.uuid,
						suit:      this.suit,
						position:  this.position,
						cards:     this.sanitiseCards(value)
					});
				}
			},
			positions () {
				return seatPositions.map((seat) => {
					return seat.text.toLowerCase();
				});
			},
			getRefName () {
				if (!this.position ||
						!this.suit) {
					return false;
				}
				return `${this.position}-${this.suit}`;
			},
			getSuitLabel () {
				if (!this.suit) {
					return undefined;
				}
				return sentenceCase(this.suit);
			},
			getOtherSuitIdsBySuit () {
				const positions = this.positions.map((thisPosition) => {
					if (thisPosition === this.position) {
						return false;
					}
					return this.suitOrder.map((thisSuit) => {
						if (thisSuit !== this.suit) {
							return false;
						}
						return `${thisPosition}-${thisSuit}`;
					}).filter(Boolean);
				}).filter(Boolean).flat();
				return positions;
			},
			getOtherHandIdsBySuit () {
				const positions = this.positions.map((thisPosition) => {
					if (thisPosition !== this.position) {
						return false;
					}
					return this.suitOrder.map((thisSuit) => {
						if (thisSuit === this.suit) {
							return false;
						}
						return `${thisPosition}-${thisSuit}`;
					}).filter(Boolean);
				}).filter(Boolean).flat();
				return positions;
			},
			getSuitValidationRules () {
				const otherSuitIds = this.getOtherSuitIdsBySuit;
				const otherHandIds = this.getOtherHandIdsBySuit;
				const suitLength = otherSuitIds.map((id) => {
					return `@${id}`;
				});
				const suitDuplicates = otherSuitIds.map((id) => {
					return `@${id}`;
				});
				const handLength = otherHandIds.map((id) => {
					return `@${id}`;
				});
				return {
					validCards: true,
					suitLength,
					suitDuplicates,
					handLength
				};
			}
		},
		watch: {},
		mounted () {},
		methods: {
			sanitiseCards (cards) {
				return cards.toUpperCase().replace('10', 'T');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='suit-editor'] {
		width:100%;
		[data-component='input-field'] {
			width:100%;
		}
	}
</style>
