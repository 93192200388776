<template>
	<div
		data-component="suit-symbol"
		v-if="getShowIcon"
	>
		<icon
			:icon="getIcon"
			:colour="getColour"
		/>
	</div>
</template>

<script>

	import Icon           from '@/components/ui/Icon';
	import { suitOrder }  from '@/consts';

	export default {
		components: {
			Icon
		},
		props: {
			suitName: {
				type: String,
				default: undefined
			},
			withoutPadding: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getShowIcon () {
				if (!this.getIcon ||
						!this.getColour) {
					return false;
				}
				return true;
			},
			getIcon () {
				if (this.withoutPadding) {
					return `${this.getSuitNameWithoutPlural}-without-padding`;
				}
				return this.getSuitNameWithoutPlural;
			},
			getSuitName () {
				if (!this.suitName) {
					return false;
				}
				return suitOrder.find((suit) => {
					const lowerCaseSuitName = this.suitName.toLowerCase();
					return suit.indexOf(lowerCaseSuitName, 0) === 0;
				});
			},
			getSuitNameWithoutPlural () {
				if (!this.getSuitName) {
					return false;
				}
				if (this.getSuitNameIsPlural) {
					return this.getSuitName.slice(0, -1);
				}
				return this.getSuitName;
			},
			getSuitNameIsPlural () {
				if (!this.getSuitName) {
					return false;
				}
				const length = this.getSuitName.length;
				if (!this.getSuitName[length - 1] === 's' ||
						!this.getSuitName[length - 1] === 'S') {
					return false;
				}
				return true;
			},
			getColour () {
				if (!this.getSuitName) {
					return false;
				}
				if (this.getSuitNameWithoutPlural === 'spade' ||
						this.getSuitNameWithoutPlural === 'club') {
					return 'black';
				}
				return 'red';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='suit-symbol'] {
	}

</style>
