<template>
	<div
		data-component="bidding-table"
		:data-selected="getIsSelected"
		:data-highlighted="getIsHighlighted"
		:data-answer="isAnswerDiagram"
		:data-selectable="getIsSelectable"
		:data-selected-and-correct-and-quiz-is-complete="getIsSelectedAndCorrectAndQuizIsComplete"
		:data-selected-and-incorrect-and-quiz-is-complete="getIsSelectedAndIncorrectAndQuizIsComplete"
		@click="selectAnswer"
	>
		<div data-element="table">
			<div data-element="positions">
				<div
					v-for="(position, key) in ['W', 'N', 'E', 'S']"
					:key="key"
					data-element="position"
				>
					{{ position }}
				</div>
			</div>
			<div
				data-element="bids"
			>
				<div
					data-element="bid"
					v-for="(bid, key) in getFormattedBids"
					:data-player="bid.player"
					:key="key"
				>
					<div data-element="value">
						{{ getValue(bid) }}
					</div>
					<icon
						v-if="getHasIcon(bid)"
						:icon="getSuit(bid)"
						:colour="getSuitColour(bid)"
					/>
				</div>
				<div
					data-element="bid"
					v-if="getPostSymbol"
				>
					{{ getPostSymbol }}
				</div>
			</div>
		</div>
		<bidding-table-editor
			v-if="isEditMode"
			:uuid="uuid"
			:isAnswerDiagram="isAnswerDiagram"
		/>
		<answer-icon
			v-if="getAnswerIcon"
			:icon="getAnswerIcon"
		/>
	</div>
</template>

<script>

	import BiddingTableEditor  from '@/components/diagrams/BiddingTableEditor';
	import Icon                from '@/components/ui/Icon';
	import AnswerIcon          from '@/components/activities/view/activityTypes/quiz/AnswerIcon';
	import quizAnswer          from '@/mixins/quizAnswer';
	import gameService         from '@/services/gameService';
	import quizAnswerIcon      from '@/mixins/quizAnswerIcon';

	export default {
		components: {
			BiddingTableEditor,
			Icon,
			AnswerIcon
		},
		mixins: [quizAnswer, quizAnswerIcon],
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			gameState: {
				type: [Object, Boolean],
				default: undefined
			}
		},
		data: () => ({
			game: undefined
		}),
		computed: {
			getDiagramIsInModal () {
				return this.$store.getters['quiz/getDiagramIsInModalByUuid']({
					uuid: this.uuid
				});
			},
			getBids () {
				return this.$store.getters['quiz/getBidsByUuid']({
					uuid: this.uuid
				});
			},
			getFormattedBids () {
				return this?.game?.auction?.bids;
			},
			getDealerPosition () {
				return this.$store.getters['quiz/getDealerPositionByUuid']({
					uuid: this.uuid
				});
			},
			getPostSymbol () {
				const postSymbol = this.$store.getters['quiz/getPostSymbolByUuid']({
					uuid: this.uuid
				});
				if (!postSymbol ||
						postSymbol === 'none') {
					return false;
				}
				if (postSymbol === 'ellipsis') {
					return '…';
				}
				if (postSymbol === 'questionMark') {
					return '?';
				}
				return '';
			}
		},
		watch: {
			getBids () {
				this.updateGame();
			},
			getDealerPosition () {
				this.updateGame();
			},
			gameState () {
				this.updateGame();
			}
		},
		mounted () {
			this.updateGame();
		},
		methods: {
			async getGame (bids, position) {
				return gameService.getNewGameFromGameState({
					gameState: {
						name: 'Bidding table',
						deal: `${position}:`,
						auction: {
							value: position,
							bids: bids
						}
					}
				});
			},
			async updateGame () {
				if (this.gameState) {
					this.game = await this.getGame(this.gameState.auction.bids, this.gameState.auction.value);
					return;
				}
				if (!this.getBids) {
					this.game = undefined;
					return false;
				}
				if (!this.getDealerPosition) {
					return false;
				}
				const bids = this.getBids.split(',');
				if (bids[bids.length - 1] === '') {
					return false;
				}
				try {
					const game = await this.getGame(bids, this.getDealerPosition);
					this.game = game;
				} catch (error) {
					return false;
				}
			},
			getValue (bid) {
				if (bid.type === 'pass') {
					return 'p';
				}
				if (bid.type === 'double') {
					return 'X';
				}
				if (bid.type === 'redouble') {
					return 'XX';
				}
				if (bid.suit === 'NT') {
					return bid.value + 'NT';
				}
				return bid.value;
			},
			getHasIcon (bid) {
				if (this.getValue(bid) === 'p' ||
						this.getValue(bid) === 'X' ||
						this.getValue(bid) === 'XX') {
					return false;
				}
				return true;
			},
			getSuit (bid) {
				if (!bid.suit) {
					return false;
				}
				if (bid.suit === 'NT') {
					return false;
				}
				if (bid.suit === 'S') {
					return 'spade';
				}
				if (bid.suit === 'D') {
					return 'diamond';
				}
				if (bid.suit === 'C') {
					return 'club';
				}
				return 'heart';
			},
			getSuitColour (bid) {
				if (!bid.suit ||
						bid.suit === 'NT') {
					return '';
				}
				if (bid.suit === 'C' ||
						bid.suit === 'S') {
					return 'black';
				}
				return 'red';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bidding-table'] {
		&[data-answer] {
			position:relative;
			padding:rem(16);
			border-radius:4px;
			border:3px solid transparent;
			&[data-selectable] {
				cursor:pointer;
			}
			&[data-selected] {
				background-color:lighten($c-brand-blue-lighter-1, 50%);
				border-color:$c-brand-blue-lighter-1;
				cursor:default;
			}
			&[data-selected-and-correct-and-quiz-is-complete] {
				background-color:lighten($c-brand-green, 50%);
				border-color:$c-brand-green;
			}
			&[data-selected-and-incorrect-and-quiz-is-complete] {
				background-color:lighten($c-brand-red, 50%);
				border-color:$c-brand-red;
				&[data-selectable] {
					cursor:pointer;
				}
			}
			&[data-highlighted] {
				border-color:$c-brand-green;
			}
			[data-component='answer-icon'] {
				position:absolute;
				bottom:rem(4);
				right:rem(4);
			}
		}
		[data-element='table'] {
			width:rem(150);
			user-select:none;
			overflow:hidden;
			border:1px solid $c-brand-black;
			[data-element='positions'] {
				display:flex;
				margin-bottom:rem(2);
				border-bottom:1px solid $c-brand-black;
				padding:0 rem(4);
				[data-element='position'] {
					display:flex;
					align-items:center;
					justify-content:center;
					width:25%;
					height:rem(22);
					text-align:center;
					font-size:rem(16);
					font-weight:700;
					text-transform:uppercase;
					color:$c-brand-black;
				}
			}
			[data-element='bids'] {
				display:flex;
				flex-wrap:wrap;
				width:100%;
				max-height:rem(192);
				min-height:rem(22);
				overflow-y:auto;
				padding:0 rem(4);
				[data-element='bid'] {
					display:flex;
					align-items:center;
					justify-content:center;
					width:25%;
					height:rem(28);
					padding:rem(5);
					[data-component='icon'] {
						flex-shrink:0;
						width:auto;
						height:rem(15);
					}
					[data-element='value'] {
						font-weight:700;
						font-size:rem(18);
						text-transform:uppercase;
					}
					&:first-child {
						&[data-player='W'] {
						}
						&[data-player='N'] {
							margin-left:25%;
						}
						&[data-player='E'] {
							margin-left:50%;
						}
						&[data-player='S'] {
							margin-left:75%;
						}
					}
				}
			}
		}
	}

</style>
